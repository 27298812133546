/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import money from 'v-money';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import titleMixin from './mixins/titleMixin';
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from 'vue-notification';
import i18n from './i18n'
import dotenv from 'dotenv'

dotenv.config()

// router setup
import router from './routes/router';
import '@/assets/css/new-main.css';
import '@/assets/css/new-deposit.css';
// import '@/assets/vendor/flag/css/flags32-both.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@/assets/vendor/flag-icons/css/flag-icons.min.css';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(money, {precision: 4});
Vue.use(VueSweetalert2);
Vue.mixin(titleMixin);
Vue.use(Notifications);


Vue.directive("uppercase", {
  update: function (el) {
      el.value = el.value.toUpperCase()
      el.dispatchEvent(new Event('input'))
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
});
