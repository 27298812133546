<template>
    <div class="conv-block d-flex w-100 align-items-center justify-content-center mb-3 p-2 pt-3 pb-3 fz-14 fw-500" @click="gotoChat()">
        <img :src="data.icon" class="mr-2"/>
        <div class="d-flex flex-column w-100 justify-content-center text-left">
            <span>{{ data.category }}</span>
            <span class="mb-1 caption date fz-12">
                <span>{{ date }}</span>
                <span>{{ time }}</span>
            </span>
        </div>
        <div class="d-flex flex-column fz-12">
            <span class="text-center caption" :class="this.status" >{{ this.status }}</span>
            <span v-if="data.total>0" class="total ml-auto">{{ data.total }}</span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        data(){
            return{
                status:'',
                date:'',
                time:''
            }
        },
        props:['data'],
        methods:{
            getMessage: function (key) {
                return this.$t(key)
            },
            gotoChat(params){
                this.$emit('goto-chat', {"room_id":this.data.room_id,"category_id":this.data.category_id});
            }
        },
        mounted(){
            this.date = moment(this.data.date).format('DD-MM-YYYY');
            this.time = moment(this.data.date).format('HH:mm:ss');
            this.status = (this.data.status==1)?this.getMessage("Open"):this.getMessage("Closed");
        }
    }
</script>

<style scoped src="@/assets/css/chat.css"></style>